export enum ContractTypeEnum {
  CDD = "cdd",
  CDI = "cdi",
  STAGE = "stage",
  ALTERNANT = "alternant",
  EXTERNE = "externe",
}

export enum GenderEnum {
  MAN = "m",
  WOMAN = "f",
  OTHER = "other",
}

export enum UserLanguageEnum {
  FR = "fr",
  EN = "en",
}

export enum UserStatusEnum {
  REGISTERED = "registered",
  UNREGISTERED = "unregistered",
  DISABLED = "disabled",
  INVITED = "invited",
}

export enum CountriesEnum {
  BRAZIL = "brazil",
  FRANCE = "france",
  ROMANIA = "romania",
  RUSSIA = "russia",
  UKRAINE = "ukraine",
  POLAND = "poland",
  SLOVAKIA = "slovakia",
  GERMANY = "germany",
  SPAIN = "spain",
  EGYPT = "egypt",
  UNITED_ARAB_EMIRATES = "united arab emirates",
  USA = "usa",
  CANADA = "canada",
  UNITED_KINGDOM = "united kingdom",
  BELGIUM = "belgium",
  MEXICO = "mexico",
  SINGAPORE = "singapore",
  SOUTH_AFRICA = "south africa",
  ITALY = "italy",
  TUNISIA = "tunisia",
  IRELAND = "ireland",
  COLOMBIA = "colombia",
  DOMINICAN_REPUBLIC = "dominican republic",
  DENMARK = "denmark",
  HUNGARY = "hungary",
  THE_NETHERLANDS = "the netherlands",
  MACEDONIA = "macedonia",
  PORTUGAL = "portugal",
  BULGARIA = "bulgaria",
  SENEGAL = "senegal",
  AUSTRIA = "austria",
}
